.main {
    display: flex;
    width: 1000px;
    flex-direction: column;
    color: #151515;
    margin: 40px auto auto auto;
    padding-bottom: 20px;
    justify-content: center;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
}

.solutions_container {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.title {
    color: #FF7F26;
    font-size: 35px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 37px;
}

.text {
    border: 1px solid #FF7F26;
    width: 400px;
    padding: 10px;
}

@media screen and (max-width: 420px) {
    .main {
        width: 90%;
        line-height: 18px;
    }
    .title {
        font-size: 24px;
        line-height: 26px;
    }
    .solutions_container {
        display: grid;
        grid-template-columns: repeat(auto-fill, 90%);
        grid-template-rows: repeat(auto-fill, 1fr);
        gap: 16px;
        justify-content: center;
    }
    .text {
        width: auto;
    }

}
@media screen and (max-width: 768px) and (min-width: 320px){
    .main {
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main {
    }
}

