.main {
    display: flex;
    flex-direction: row;
    margin: 100px auto 100px auto;
    gap: 50px;
    align-items: center;
}


.info_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 230px);
    grid-template-rows: repeat(auto-fill, 1fr);
    gap: 40px;
    width: 600px;
    justify-content: center;
}
.image {
    width: 100%;
    height: 100%;
    border-radius: 150px;
}
.img_box {
    width: 320px;
    height: 450px;
}

.contacts_container {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 350;
    color: #151515;
    width: 500px;
}

.title {
    font-weight: 350;
    font-size: 50px;
    line-height: 60px;
    margin-left: 50px;
}

.info_box {
    display: flex;
    flex-direction: column;
}

.icons {
    display: flex;
    margin-bottom: 10px;
    flex-direction: row;
    gap: 16px;
    margin-top: 10px;
}
.icon {
    width: 45px;
    height: 45px;
}

.info_title {
    font-weight: 320;
    font-size: 19px;
    color: #7F7F7F;
    margin-bottom: 0;
}
.tel {
    display: block;
    margin-bottom: -10px;
}
.link {
    text-decoration: none;
    color: #151515;
    margin-top: 10px;
    margin-bottom: -10px;
}
.link:hover {
    opacity: 0.7;
}
.link_phone {
    display: none;
    color: #151515;
    margin-top: 10px;
    margin-bottom: -10px;
    text-decoration: none;
}
.to_top {
    width: 50px;
    height: 50px;
    margin-bottom: -400px;
    margin-right: 20px;
}
.to_top_img {
    border-radius: 100px;
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 420px) {
    .main {
        width: 100%;
        margin: 50px auto 50px auto;
    }
    .tel {
        display: none;
    }
    .link_phone {
        display: block;
        width: 170px;
    }
    .info_title {
        width: 170px;
    }
    .info_container {
        margin-right: 0;
        width: 70%;
        grid-template-columns: repeat(auto-fill, 90%);
        column-gap: 0px;
    }
    .contacts_container {
        width: 80%;
        margin: auto;
    }
    .title {
        font-size: 40px;
        margin-left: 20px;
    }
    .img_box {
        display: none;
    }
    .info_box {
        margin-left: 20px;
    }
    .to_top {
        width: 60px;
        height: 60px;
    }

}
@media screen and (max-width: 768px) and (min-width: 320px){
    .main {
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main {
    }
}