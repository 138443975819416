.main {
    display: flex;
    width: 1000px;
    flex-direction: column;
    color: #151515;
    margin: 40px auto 20px auto;
    padding-bottom: 20px;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    white-space: pre-line;
    border-bottom: 1px solid #7F7F7F;
}
.title {
    color: #FF7F26;
    font-size: 35px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 37px;
}

.services_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 500px);
    grid-template-rows: repeat(auto-fill, 1fr);
    gap: 40px;
    width: 1000px;
    justify-content: center;
}

.service {
    border: 1px solid #FF7F26;
    text-align: center;
}

@media screen and (max-width: 420px) {
    .main {
        width: 90%;
        line-height: 18px;
    }
    .title {
        font-size: 24px;
        line-height: 26px;
    }
    .services_container {
        width: 100%;
        grid-template-columns: repeat(auto-fill, 90%);
    }

}
@media screen and (max-width: 768px) and (min-width: 320px){
    .main {
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main {
    }
}