.main {
    width: 1000px;
    border-bottom: 1px solid #7F7F7F;
    display: flex;
    flex-direction: row;
    padding-bottom: 50px;
    color: #151515;
    font-weight: 300;
    font-size: 16px;
    justify-content: start;
    margin: auto;
    gap: 30px;
}

.info_container {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.title {
    color: #FF7F26;
    font-size: 35px;
    font-weight: 300;
    text-transform: uppercase;
}

.img_box {
    width: 350px;
    height: 500px;
}

.img {
    height: 100%;
    width: 100%;
}
@media screen and (max-width: 420px) {
    .main {
        width: 90%;
    }
    .img_box {
        display: none;
    }
    .title {
        font-size: 24px;
    }
    .info_container {
        width: 100%;
    }

}
@media screen and (max-width: 768px) and (min-width: 320px){
    .main {
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main {
    }
}