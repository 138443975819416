.main {
    display: grid;
    grid-template-columns: 670px 640px;
    grid-column-gap: 100px;
    width: auto;
    grid-template-rows: 200px 100px 55px;
    color: #151515;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    margin: 50px auto auto auto;
    overflow-x: hidden;
    overflow-y: hidden;
}

.link {
    display: none;
    text-decoration: none;
    color: #151515;
    font-size: 20px;
}

.hover {
    display: block;
    text-decoration: none;
    color: #151515;
    font-size: 20px;
}

.header {
    grid-row: 1 / 2;
    font-weight: 430;
    font-size: 50px;
    line-height: 60px;
}

.header_text {
    margin-top: 0;
    grid-row: 2 / 3;
}

.button {
    grid-row: 3 / 4;
    background-color: #FFA567;
    border: 1px solid #151515;
    border-radius: 100px;
    width: 200px;
}

.img_box {
    grid-row: 1 / 4;
    grid-column: 2 / 3;
    margin: 30px auto auto auto;
    width: 630px;
    height: 320px;
}

.img {
    height: 100%;
    width: 100%;
    border-radius: 170px;
}

.name {
    font-weight: 550;
}

@media screen and (max-width: 420px) {
    .main {
        grid-template-rows: 250px 100px 140px 55px;
        grid-template-columns: 100%;
        height: auto;
        gap: 20px;
        width: 100%;
        margin-top: 0;
    }
    .img_box {
        grid-row: 1 / 2;
        grid-column: 1 / 1;
        width: 100%;
        height: 250px;
        margin: auto;
    }
    .img {
        border-radius: 0;
    }
    .header {
        margin-left: 16px;
        margin-bottom: 0;
        font-weight: 430;
        grid-row: 2 / 3;
        font-size: 30px;
        line-height: 35px;
    }
    .header_text {
        grid-row: 3 / 4;
        margin-left: 16px;
    }
    .button {
        grid-row: 4 / 5;
        margin-left: 16px;
    }
    .link {
        display: block;
    }
    .hover {
        display: none;
    }
}

@media screen and (max-width: 768px) and (min-width: 320px){
    .main {
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main {
    }
}