.main {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-left: 100px;
    white-space: pre-line;
}

.main_container {
    display: flex;
    flex-direction: row;
    gap: 70px;
    margin: auto;
}

.steps_container {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 700px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: black;
}
.header {
    color: #7F7F7F;
    font-weight: 300;
    font-size: 35px;
}

.number {
    font-weight: 320;
    font-size: 30px;
}

.step_box {
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    justify-content: start;
    height: 120px;
    border-bottom: 1px solid #151515;
}

.img_box {
    width: 400px;
    height: 600px;
    margin-top: 100px;
}
.img {
    width: 100%;
    height: 100%;
    border-radius: 200px;
}

@media screen and (max-width: 420px) {
    .header {
        font-size: 20px;
        margin-left: 16px;
    }
    .main {
        margin: auto;
        width: 90%;
        margin-left: 0;
    }
    .steps_container {
        width: 100%;
        font-size: 16px;
        line-height: 18px;
        padding-left: 16px;
    }
    .number {
        font-size: 20px;
    }
    .img_box {
        display: none;
    }

}
@media screen and (max-width: 768px) and (min-width: 320px){
    .main {
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main {
    }
}
