.main {
    width: 100%;
    min-height: 190px;
    background-color: #151515;
    color: #7F7F7F;
    font-size: 16px;
    font-weight: 400;
    flex-direction: row;
    gap: 100px;
    padding-top: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 20%);
    grid-template-rows: repeat(auto-fill, 1fr);
}

.sign {
    margin-left: 200px;
}

.link_box {
    display: flex;
    flex-direction: column;
    width: 200px;
    gap: 20px;
}

.link {
    text-decoration: none;
    color: #7F7F7F;
}
.link:hover {
    opacity: 0.7;
}
.requisites{
    line-height: 21px;
}

@media screen and (max-width: 420px) {
    .main {
        gap: 30px;
        grid-template-columns: repeat(auto-fill, 70%);
    }
    .sign {
        margin-left: 30px;
    }
    .link_box {
        margin-left: 30px;
    }
    .requisites {
        margin-left: 30px;
    }

}
@media screen and (max-width: 768px) and (min-width: 320px){
    .main {
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main {
    }
}

