.main {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin: 100px auto 100px auto;
}

.header {
    color: #7F7F7F;
    font-weight: 300;
    font-size: 35px;
}

.picture_box {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.picture {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 170px;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
}

.picture_img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.picture:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}
.picture:hover:after {
    background: rgba(0, 0, 0, 0.5);
}
.picture_title {
    font-weight: 550;
    font-size: 20px;
    text-align: center;
    margin: auto;
    z-index: 2;
}

@media screen and (max-width: 420px) {
    .header {
        color: #7F7F7F;
        font-weight: 300;
        font-size: 20px;
        margin: auto;
    }
    .picture_box {
        display: grid;
        grid-template-columns: repeat(auto-fill, 90%);
        grid-template-rows: repeat(auto-fill, 1fr);
        gap: 16px;
    }
    .main {
        width: 90%;
        align-items: center;
        margin: 50px auto 50px auto;
        gap: 20px;
    }

}
@media screen and (max-width: 768px) and (min-width: 320px){
    .main {
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main {
    }
}