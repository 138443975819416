.main {
    display: flex;
    width: 1000px;
    flex-direction: column;
    color: #151515;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    border-bottom: 1px solid #7F7F7F;
    margin: 40px auto auto auto;
    padding-bottom: 20px;
}
.title {
    color: #FF7F26;
    font-size: 35px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 37px;
}

@media screen and (max-width: 420px) {
    .main {
        width: 90%;
    }
    .title {
        font-size: 24px;
        line-height: 26px;
    }

}
@media screen and (max-width: 768px) and (min-width: 320px){
    .main {
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main {
    }
}

